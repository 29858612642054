import crypto from "crypto-browserify";
import { Buffer } from "buffer";

const getEncryptionKey = () => {
  const key = process.env.REACT_APP_EDITOR_ENCRYPTION_KEY;
  if (!key) {
    throw new Error("Encryption key is not defined");
  }
  if (key.length !== 64) {
    throw new Error(
      "Encryption failed: key must be 32 bytes (64 hex characters)",
    );
  }
  return Uint8Array.from(Buffer.from(key, "hex"));
};

const isPasswordEncrypted = (password) => {
  try {
    const key = getEncryptionKey();
    const data = Uint8Array.from(Buffer.from(password, "base64"));
    const iv = data.slice(0, 16);
    const encryptedText = data.slice(16);

    const decipher = crypto.createDecipheriv("aes-256-cbc", key, iv);
    decipher.update(encryptedText);
    decipher.final();
    return true;
  } catch (e) {
    return false;
  }
};

function encryptPassword(password) {
  const key = getEncryptionKey();
  const iv = crypto.randomBytes(16);

  const cipher = crypto.createCipheriv("aes-256-cbc", key, iv);
  let encrypted = cipher.update(password, "utf8", "base64");
  encrypted += cipher.final("base64");

  const encryptedPassword = Buffer.concat([
    iv,
    Buffer.from(encrypted, "base64"),
  ]).toString("base64");

  return encryptedPassword;
}

export { isPasswordEncrypted, encryptPassword };
