import { configureStore, combineReducers } from "@reduxjs/toolkit";
import { persistStore, persistReducer } from "redux-persist";
import storageSession from "redux-persist/lib/storage/session";
import autoMergeLevel2 from "redux-persist/lib/stateReconciler/autoMergeLevel2";
import { createFilter } from "redux-persist-transform-filter";

import EditorReducer from "./redux/EditorSlice";
import InstructionsReducer from "./redux/InstructionsSlice";
import SchoolReducer from "./redux/SchoolSlice";
import ClassReducer from "./redux/ClassSlice";
import LessonReducer from "./redux/LessonSlice";

const saveUserLoginSubsetFilter = [
  createFilter("class", ["id"]),
  createFilter("school", ["id"]),
  createFilter("lesson", ["id"]),
];

const persistConfig = {
  key: "root",
  storage: storageSession,
  whitelist: ["school", "class", "lesson"],
  stateReconciler: autoMergeLevel2,
  transforms: [...saveUserLoginSubsetFilter],
};

const rootReducer = combineReducers({
  editor: EditorReducer,
  instructions: InstructionsReducer,
  school: SchoolReducer,
  class: ClassReducer,
  lesson: LessonReducer,
});

const persistedReducer = persistReducer(persistConfig, rootReducer);

export const store = configureStore({
  reducer: persistedReducer,
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: {
        ignoredActions: [
          "redux-oidc/USER_FOUND",
          "redux-odic/SILENT_RENEW_ERROR",
          "persist/PERSIST",
          "persist/REHYDRATE",
        ],
      },
    }),
});

export const persistor = persistStore(store);
