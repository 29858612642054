import axios from "axios";
import { isPasswordEncrypted } from "../crypto";

import { headers, host } from "./shared";

export const createStudent = async (student, schoolId, accessToken) => {
  let response;
  try {
    if (!isPasswordEncrypted(student.password)) {
      throw new Error("Password must be encrypted");
    }

    response = await axios.post(
      `${host}/api/schools/${schoolId}/students`,
      { school_student: student },
      headers(accessToken),
    );
    return response.data;
  } catch (error) {
    const errorMessage = error?.response?.data?.error;
    throw errorMessage ? new Error(errorMessage) : error;
  }
};

export const createStudents = async (students, schoolId, accessToken) => {
  let response;
  try {
    if (!students.every((student) => isPasswordEncrypted(student.password))) {
      throw new Error("All passwords must be encrypted");
    }

    response = await axios.post(
      `${host}/api/schools/${schoolId}/students/batch`,
      { school_students: students },
      headers(accessToken),
    );
    return response.data;
  } catch (error) {
    const data = error?.response?.data?.error;
    // TODO: Handle this more gracefully (a custom error would be ideal, but custom props were being stripped by axios)
    throw data ? JSON.stringify(data) : error;
  }
};

export const putStudentDetails = async (
  student,
  studentId,
  schoolId,
  accessToken,
) => {
  let response;
  try {
    if (student.password && !isPasswordEncrypted(student.password)) {
      throw new Error("Password must be encrypted");
    }

    response = await axios.put(
      `${host}/api/schools/${schoolId}/students/${studentId}`,
      { school_student: student },
      headers(accessToken),
    );
    return response.data;
  } catch (error) {
    const errorMessage = error?.response?.data?.error;
    throw errorMessage ? new Error(errorMessage) : error;
  }
};
